class PageLoader {

  constructor(componentsRequired) {
    this.componentsCompleted = 0;
    this.componentsRequired = componentsRequired;
    this.error = false;
  }

  addRequiredComponent(components = 1) {
    this.componentsRequired += components;
  }

  setCompleteComponent(components = 1) {
    this.componentsCompleted += components;
  }

  setError(val) {
    this.error = val; 
  }

  getStatus() {
    return ((this.componentsCompleted >= this.componentsRequired) && !this.error) ? true : false;
  }

}

export default PageLoader;