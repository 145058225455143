class AutoCompleteHelper {

  //Suggests a key based on either a client or project name
  static generateKey(name) {
    let key = "";

    if (name.length <= 3 || name.indexOf(' ') == -1) {
      key = name.toLowerCase();
      key = key.replace(/ /g, "_");
      key = key.replace(/[^a-z0-9_]+/, "");
    } else {
      let nameArray = name.split(" ");
      nameArray.forEach(function (word) {
        if (word.length > 0) {
          key = key + word[0].toLowerCase();
        }
      });
    }

    return key;
  }

}

export default AutoCompleteHelper;