<template>
  <div class="row col-xl-12 mx-0 px-0">
    <!--start::TOPFORM-->
    <Topform
      ref="project-topform"
      :managementType="managementType"
      :status="calculatedStatus"
      pageType="project"
      :name="projectInfo.name"
      :errors="projectErrors"
      :submissionStates="submissionStates"
      parentPage="/client/projects"
      :tabs="computedTabs"
      :validation="computedValidation"
      :loadingComplete="loadingComplete"
      @start_date_updated="form.starts_on = $event"
      @end_date_updated="form.ends_on = $event"
      @admin_date_updated="form.admin_end_date = $event"
      @clearErrors="clearErrors"
      @submit="onSubmit"
      @clear_existing_data="clearData"
      @registration_option_change="updateAdditionalRegistrations"
      @on-tab-changed="tab = $event"
    />

    <RegistrationVariables
      v-if="managementType == 'update' && tab == 2 && loadingComplete"
      :registration_variables="form.registration_variables"
      :additional_registration_options="form.additional_registration_options"
      :submissionStates="submissionStates"
      @on-variables-change="form.registration_variables = $event"
    />
    <!--end::TOPFORM-->
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapMutations
} from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength, requiredIf } from "vuelidate/lib/validators";
import { SET_BREADCRUMB } from "@/core/services/store/system/breadcrumbs.module";
import moment from "moment";
import AutoCompleteHelper from "@/helpers/AutoCompleteHelper";
import PageLoader from "@/core/classes/PageLoader.js";

//Vuex Imports
import {
  CLEAR_PROJECT_ERRORS,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  UPDATE_PROJECT_INFO,
  CLEAR_PROJECT_INFO
} from "@/core/services/store/project/project.module";

import { POPULATE_CLIENT_USER_LIST } from "@/core/services/store/core/user.module";

//Topform Imports
import TopformConfig from "@/core/config/topform/ManageProject.js";

import RegistrationVariables from "@/view/content/projects/ProjectRegistrationVariables";

export default {
  mixins: [validationMixin],

  name: "ManageProject",

  data() {
    return {
      tab: 1,
      pageLoader: new PageLoader(3),
      error: {
        active: false,
        message: ""
      },
      submissionStates: {
        submitting: false
      },
      form: {
        published: 1,
        name: "",
        key: "",
        hubspot_reference: "",
        demo_project: "0",
        starts_on: null,
        ends_on: null,
        admin_end_date: null,
        system_language_code: 'eng',
        system_timezone_code: 'UTC',
        deal_owner: "",
        participant_limit: 1,
        additional_registration_options: [],
        registration_variables: []
      },
      allow_key_editing: true,
      hs_reference_disabled: false,
      supportedParticipantsList: []
    };
  },

  validations: {
    form: {
      published: {
        required
      },
      name: {
        required,
        maxLength: maxLength(255)
      },
      key: {
        required,
        maxLength: maxLength(35)
      },
      hubspot_reference: {
        required: requiredIf(function() {
          return this.submissionType == "standard"
        }),
        maxLength: maxLength(10)
      },
      demo_project: {},
      starts_on: {
        required
      },
      ends_on: {
        required
      },
      admin_end_date: {
        required
      },
      system_language_code: {
        required
      },
      system_timezone_code: {
        required
      },
      deal_owner: {
        required: requiredIf(function() {
          return this.submissionType == "standard"
        })
      },
      participant_limit: {
        required
      }
    }
  },

  components: {
    RegistrationVariables
  },

  mounted() {
    let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `${this.$t("NAVIGATION.ROOT_MENU.CLIENTS")}`, route: protocol + 'admin.' + process.env.VUE_APP_API_ROOT + "/#/clients/view" },
      { title: this.clientInfo.name, route: protocol + this.clientInfo.key + ".admin." + process.env.VUE_APP_API_ROOT + "/#/client/projects" },
      { title: `${this.$t("NAVIGATION.BREADCRUMB.ADD_PROJECT")}` }
    ]);
    this.fetchSupportedParticipantRanges();
    this.setClientDefaults();
    this.getSystemAdministrators();

    if (this.managementType == "update") {
      this.pageLoader.addRequiredComponent(2);
      this.fetchExistingProject();
    } else {
      this.form.starts_on = moment(new Date()).utc().format("YYYY-MM-DD") + " 00:00:00";
      this.form.ends_on = moment(this.form.starts_on, "YYYY-MM-DD HH:mm:ss").utc().add('days', 30).format("YYYY-MM-DD") + " 00:00:00";
    }
  },

  methods: {
    getSystemAdministrators: function() {
      let payload = {
        clientId: this.clientInfo.id,
        params: {
          paginate: false,
          read_only: 1,
          setup_complete: 1
        }
      };
      this.$store
        .dispatch(POPULATE_CLIENT_USER_LIST, payload)
        .then(() => {
          if (this.submissionType == "post") {
            this.form.deal_owner = this.userList[0].id;
          }
          this.pageLoader.setCompleteComponent();
        });
    },
    fetchSupportedParticipantRanges: function() {
      let payload = {
        route: "api/v1/credit_schemes_rules/supported_paticipants"
      };
      this.$store.dispatch(GENERIC_RESOURCE, payload).then((res) => {
        this.supportedParticipantsList = res;
        this.pageLoader.setCompleteComponent();
      });
    },
    fetchExistingProject: function() {
      let payload = {
        clientIdentifier: this.clientInfo.id,
        projectIdentifier: this.$route.params.id,
        params: {
          assignedProjectRegistrationVariables: 'with',
          systemActivityTypes: 'with',
          responses: 'withCount'
        }
      };
      this.$store
        .dispatch(UPDATE_PROJECT_INFO, payload)
        .then(() => {
          this.form.published = this.projectInfo.published;
          this.form.name = this.projectInfo.name;
          this.form.key = this.projectInfo.key;
          this.form.starts_on = this.projectInfo.starts_on;
          this.form.ends_on = this.projectInfo.ends_on;
          this.form.admin_end_date = this.projectInfo.admin_end_date;
          this.form.system_language_code = this.projectInfo.system_language_code;
          this.form.system_timezone_code = this.projectInfo.system_timezone_code;
          this.form.participant_limit = this.projectInfo.participant_limit;

          const self = this;
          this.projectInfo.assigned_project_registration_variables.forEach(function(registerVariable) {
            self.form.registration_variables.push(
              (registerVariable.variable_type == 'ProjectSegmentation') ? `segmentation-${registerVariable.variable_id}` : `profile-question-${registerVariable.variable_id}`
            );
          })

          if (this.submissionType == "standard") {
            this.form.hubspot_reference = this.projectInfo.hubspot_reference;
            this.form.demo_project = this.projectInfo.demo_project;
            this.form.deal_owner = this.projectInfo.deal_owner;
          }

          this.pageLoader.setCompleteComponent();
          this.fetchProjectSegmentations();
        });
    },
    fetchProjectSegmentations: function() {
      let payload = {
        route: `api/v1/projects/${this.projectInfo.id}/project_segmentations`,
        params: {
          paginate: false,
          projectSegments: 'with'
        }
      };

      this.genericResource(payload).then((res) => {
        this.setProjectSegmentationList(res._embedded.project_segmentations);
        this.pageLoader.setCompleteComponent();
      }).catch((error) => {
        this.setProjectSegmentationErrors(error.response);
      })
    },
    setClientDefaults: function() {
      this.form.system_language_code = this.clientInfo.system_language_code;
      this.form.system_timezone_code = this.clientInfo.system_timezone_code;
      this.pageLoader.setCompleteComponent();
    },
    clearData: function() {
      this.$store.dispatch(CLEAR_PROJECT_INFO)
    },
    onSubmit: function() {
      this.clearErrors();

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.scrollUp();
        return;
      }

      if (this.managementType == "update") {
        this.submitUpdate();
      } else {
        this.submitPost();
      }
    },
    submitPost: function() {
      this.submissionStates.submitting = true;

      let payload = {
        clientId: this.clientInfo.id,
        data: this.form
      };

      payload.data.activity_types = this.computedInitialActivityTypes;

      this.$store
        .dispatch(CREATE_PROJECT, payload)
        .then(() => {
          let clientKey = this.clientInfo.key;
          let projectKey = this.form.key;
          let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
          window.location.href = protocol + projectKey + "-" + clientKey + '.' + process.env.VUE_APP_ADMIN_URL_SUFFIX + "/#/project/view";
        })
        .finally(() => {
          this.scrollUp();
          this.submissionStates.submitting = false;
        });
    },
    submitUpdate: function() {
      let originalKey = this.projectInfo.key;
      this.submissionStates.submitting = true;

      let payload = {
        clientId: this.clientInfo.id,
        projectId: this.projectInfo.id,
        data: this.form
      };

      payload.data.activity_types = this.computedInitialActivityTypes;

      this.$store
        .dispatch(UPDATE_PROJECT, payload)
        .then(() => {
          if (originalKey != this.form.key) {
            let clientKey = this.clientInfo.key;
            let projectKey = this.form.key;
            let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
            window.location.href = protocol + projectKey + "-" + clientKey + '.' + process.env.VUE_APP_ADMIN_URL_SUFFIX + "/#/project/view";
          } else {
            this.$router.push({ name: 'view_project' });
          }
        })
        .finally(() => {
          this.scrollUp();
          this.submissionStates.submitting = false;
        });
    },
    updateAdditionalRegistrations: function(value) {
      if (parseInt(value.value) == 1 && this.form.additional_registration_options.includes(value.id) == false) {
        this.form.additional_registration_options.push(value.id);
      } else if (parseInt(value.value) == 0 && this.form.additional_registration_options.includes(value.id)) {
        const index = this.form.additional_registration_options.indexOf(value.id);
        if (index > -1) {
          this.form.additional_registration_options.splice(index, 1);
        }
      }
    },
    clearErrors: function() {
      this.$store.dispatch(CLEAR_PROJECT_ERRORS);
    },
    autofillKey() {
      if (this.allow_key_editing && this.managementType != "update") {
        this.form.key = AutoCompleteHelper.generateKey(this.form.name);
      }
    },
    autoFillAdminEnd: function() {
      if (this.form.ends_on != null && (this.managementType == 'post' || (this.loadingComplete && this.managementType == 'update'))) {
        let adminEnd = moment.utc(this.form.ends_on, "YYYY-MM-DD HH:mm:ss").add('days', 40).format("YYYY-MM-DD HH:mm:ss");
        this.form.admin_end_date = adminEnd;
      }
    },
    scrollUp: function() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    ...mapActions({
      genericResource: "genericResource"
    }),
    ...mapMutations({
      setProjectSegmentationList: "setProjectSegmentationList",
      setProjectSegmentationErrors: "setProjectSegmentationErrors"
    })
  },

  watch: {
    calculatedName: function () {
      this.autofillKey();
    },
    'form.key': function(newValue) {
      if (newValue != AutoCompleteHelper.generateKey(this.form.name)) {
        this.allow_key_editing = false;
      }
    },
    calculatedInputEnd: function() {
      this.autoFillAdminEnd();
    },
    isDemo: function() {
      if (this.isDemo) {
        this.hs_reference_disabled = true;
        this.form.hubspot_reference = "demo";
      } else {
        this.hs_reference_disabled = false;
        this.form.hubspot_reference = "";
      }
    }
  },

  computed: {
    ...mapGetters([
      "clientInfo",
      "projectInfo",
      "currentUser",
      "projectErrors",
      "systemLanguages",
      "systemTimezones",
      "systemActivityTypes",
      "userList"
    ]),

    loadingComplete: function() {
      return this.pageLoader.getStatus();
    },

    calculatedStatus: function() {
      if (this.managementType == "update" && this.loadingComplete == true) {
        if (this.projectInfo.published) {
          return "published";
        } else {
          return "unpublished";
        }
      } else {
        return null;
      }
    },

    calculatedName: function() {
      return this.form.name;
    },

    calculatedInputEnd: function() {
      return this.form.ends_on;
    },

    isDemo: function() {
      if (this.form.demo_project == "1") {
        return true;
      } else {
        return false;
      }
    },

    submissionType: function() {
      return "standard";
    },

    managementType: function() {
      if (this.$route.params.id != undefined) {
        return "update";
      } else {
        return "post"
      }
    },

    computedProjectDuration: function() {
      if (this.form.starts_on != null && this.form.ends_on != null) {
        let start = moment(this.form.starts_on, "YYYY-MM-DD HH:mm:ss");
        let end = moment(this.form.ends_on, "YYYY-MM-DD HH:mm:ss");
        return end.diff(start, 'days');
      } else {
        return null;
      }
    },

    computedInitialActivityTypes: function() {
      return [1,2,3,4,5,6,7,8,9];
    },

    inputStartUL: function() {
      if (this.form.ends_on == null) {
        return null;
      } else {
        return this.form.ends_on;
      }
    },

    inputEndLL: function() {
      if (this.form.starts_on == null) {
        return moment(new Date()).utc().format("YYYY-MM-DD") + " 00:00:00";
      } else {
        return this.form.starts_on;
      }
    },

    adminEndLL: function() {
      if (this.form.ends_on == null) {
        return moment(new Date()).utc().format("YYYY-MM-DD") + " 00:00:00";
      } else {
        return this.form.ends_on;
      }
    },

    adminEndUL: function() {
      if (this.form.ends_on == null || this.currentUser.system_admin_role_id == 1) {
        return null;
      } else {
        let date = moment(this.form.ends_on, "YYYY-MM-DD HH:mm:ss").add('days', 40).utc().format(("YYYY-MM-DD"));
        return date + " 00:00:00";
      }
    },

    computedValidation: function() {
      return this.$v.form;
    },
    
    computedTabs: function() {
      return TopformConfig.topform(this);
    },

    tab1Errors: function() {
      if (
        this.$v.form.published.$anyError ||
        this.$v.form.name.$anyError ||
        this.$v.form.key.$anyError ||
        this.$v.form.hubspot_reference.$anyError ||
        this.$v.form.demo_project.$anyError ||
        this.$v.form.starts_on.$anyError ||
        this.$v.form.ends_on.$anyError ||
        this.$v.form.admin_end_date.$anyError ||
        this.$v.form.system_language_code.$anyError ||
        this.$v.form.system_timezone_code.$anyError ||
        this.$v.form.deal_owner.$anyError ||
        this.$v.form.participant_limit.$anyError
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
