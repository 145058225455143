<template>
  <div class="pr-0 col-xl-4">
    <div class="card card-custom gutter-b mb-0">
      <div class="card-body">
        <h3
          class="font-size-lg font-weight-bold px-0"
          :class="{ 'text-dark' : !disabled, 'text-muted' : disabled }"
        >
          {{ $t('FORMS.SECTION_HEADERS.PROJECT_VARIABLES_TITLE') }}
          <span
            class="svg-icon svg-icon-md ml-2"
            v-b-tooltip.hover.right
            :title="'To activate variable settings allow anonymous or on the fly registrations'"
          >
            <inline-svg
              src="media/svg/icons/Code/Info-circle.svg"
            />
          </span>
        </h3>
        <p :class="{ 'text-muted' : disabled }">{{ $t('FORMS.SECTION_HEADERS.PROJECT_VARIABLES_SUBTITLE') }}</p>
        <ViewVariable
          v-for="(profileQuestion, pc) in allowedProfileQuestions"
          :key="`profile-question-${pc}`"
          :registration_variables="registration_variables"
          :submissionStates="submissionStates"
          :variableParent="profileQuestion"
          labelField="name"
          :variableChildKey="null"
          variablePrefix="profile-question"
          :editable="false"
          :disabled="additional_registration_options.length == 0"
          @on-variables-change="$emit('on-variables-change',$event)"
        />

        <p class="font-weight-bold mt-7" :class="{ 'text-muted' : disabled }">{{ $t('FORMS.SECTION_HEADERS.PROJECT_VARIABLES_SEGMENTS') }}</p>

        <ViewVariable
          v-for="(segmentation, sg) in projectSegmentations"
          :key="`segmentation-${sg}`"
          :registration_variables="registration_variables"
          :submissionStates="submissionStates"
          :variableParent="segmentation"
          :disabled="additional_registration_options.length == 0"
          labelField="name"
          variableChildKey="project_segments"
          variablePrefix="segmentation"
          @on-variables-change="$emit('on-variables-change',$event)"
          @on-edit-variable-values="
            opened++,
            $refs['edit-variable-modal'].cleanup(),
            editableParent = {...$event, ...{ opened: opened } },
            $refs['edit-variable-modal'].$refs.modal.show()
          "
        />
      </div>
    </div>

    <EditVariable
      ref="edit-variable-modal"
      :editableParent="editableParent"
      :submissionStates="submissionStates"
      labelField="name"
      variableChildKey="project_segments"
    />
  </div>
</template>

<script>
import {
  mapGetters
} from "vuex"

import ViewVariable from "./project_registration_variables/ViewVariable";
import EditVariable from "./project_registration_variables/EditVariable";

import PageLoader from "@/core/classes/PageLoader.js";

export default {
  name: "ProjectRegistrationVariables",

  props: {
    registration_variables:           { type: Array, required: true },
    additional_registration_options:  { type: Array, required: true },
    submissionStates:                 { type: Object, required: true }
  },

  data() {
    return {
      editableParent: null,
      opened: 1
    }
  },

  components: {
    ViewVariable,
    EditVariable
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "projectSegmentations",
      "projectSegmentationErrors",
      "systemProfileQuestions"
    ]),
    allowedProfileQuestions: function() {
      return this.systemProfileQuestions.filter(function(profileQuestion) {
        return profileQuestion.id == 6
      })
    },
    disabled: function() {
      return this.additional_registration_options.length == 0;
    }
  }
}
</script>