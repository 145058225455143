<template>
  <div>
    <div class="d-flex justify-content-between view-variable">
      <b-form-checkbox
        size="lg"
        :value="1"
        v-model="model"
        :unchecked-value="0"
        :disabled="submissionStates.submitting == true || disabled === true"
        @change="onVariableToggle($event)"
        style="overflow: hidden"
      >
        <span class="font-size-base pl-1">
          <span :class="{ 'text-muted' : disabled }">
            {{ `${variableParent[labelField]}` }}
          </span>
          <span
            v-if="registration_variables.includes(uniqueIdentifier)"
            class="text-muted pl-2"
            style="font-size: .925rem"
          >
            {{ ` - ${variableParent[variableField]}` }}
          </span>
        </span>          
      </b-form-checkbox>

      <a
        v-if="editable && registration_variables.includes(uniqueIdentifier) && !disabled"
        href="javascript:void(0)"
        @click="$emit('on-edit-variable-values', variableParent)"
      >
        <i
          class="fas fa-pencil-alt"
          style="font-size:1rem"
        />
      </a>
    </div>


    <ul
      v-if="variableChildKey != null"
      class="pl-12"
      style="list-style-type: inherit; line-height: 2rem"
    >
      <li
        v-for="(child, c) in variableParent[variableChildKey]"
        :key="`${variableParent[valueField]}-${c}`"
      >
        <span :class="{ 'text-muted' : disabled }">
          {{ child[labelField] }}
        </span>
        <span
          v-if="registration_variables.includes(uniqueIdentifier)"
          class="text-muted pl-2"
          style="font-size: .925rem"
        >
          {{ ` - ${child[variableField]}` }}
        </span>
      </li>
    </ul>
  </div>

</template>

<script>
export default {
  name: "ViewProjectRegistrationVariable",

  props: {
    registration_variables: { type: Array, required: true },
    submissionStates:       { type: Object, required: true },
    variableParent:         { type: Object, required: true },
    variableChildKey:       { type: String, required: false, default: null },
    valueField:             { type: String, required: false, default: "id" },
    labelField:             { type: String, required: false, default: "label" },
    variableField:          { type: String, required: false, default: "key" },
    variablePrefix:         { type: String, required: true },
    editable:               { type: Boolean, required: false, default: true },
    disabled:               { type: Boolean, required: true }
  },

  data() {
    return {
      model: null
    }
  },

  mounted() {
    this.model = (this.registration_variables.includes(this.uniqueIdentifier)) ? 1 : 0;
  },

  methods: {
    onVariableToggle: function(newValue) {
      let newRegistrationVariables = JSON.parse(JSON.stringify(this.registration_variables));
      let uniqueIdentifier = this.uniqueIdentifier;

      if (newValue == 1) {
        let existingEntry = newRegistrationVariables.filter(function(variable) {
          return variable == uniqueIdentifier;
        })
        if (existingEntry.length == 0) {
          newRegistrationVariables.push(this.uniqueIdentifier);
        }
      } else {
        newRegistrationVariables = newRegistrationVariables.filter(function(variable) {
          return variable != uniqueIdentifier
        });
      }

      this.$emit('on-variables-change',newRegistrationVariables);
    }
  },

  computed: {
    uniqueIdentifier: function() {
      return `${this.variablePrefix}-${this.variableParent[this.valueField]}`
    }
  }
}
</script>