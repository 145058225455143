<template>
  <b-modal
    ref="modal"
    size="lg"
  >
    <template #modal-title>
      {{ $t('MODALS.TITLE.EDIT_VARIABLES') }}
    </template>

    <template #modal-footer>
      <div class="d-flex justify-content-end">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary mr-3"
          :disabled="submissionStates.submitting === true || localSubmissionState.submitting === true"
          @click="$refs.modal.hide()"
        >
          {{ $t('GENERAL.BUTTONS.CANCEL') }}
        </a>

        <a
          href="javascript:void(0)"
          class="btn btn-primary"
          :disabled="submissionStates.submitting === true || localSubmissionState.submitting === true"
          @click="onSubmit()"
        >
          {{ $t('GENERAL.BUTTONS.SAVE') }}
        </a>
      </div>
    </template>

    <div
      class="px-10 py-1"
      v-if="model.constructed == true"
    >
      <ErrorBox
        :errors="projectSegmentationErrors"
        @clear_errors="setProjectSegmentationErrors({})"
      />

      <div class="d-flex justify-content-start align-items-center pt-2 pb-7">
        <span class="font-weight-bold col-4 px-0"></span>
        <span class="font-weight-bold col-8 px-0">{{ $t('FORMS.INPUTS.VARIABLE_NAME') }}</span>
      </div>

      <div
        class="d-flex justify-content-start align-items-center"
        :class="{ 'pb-2' : variableChildKey == null || (variableChildKey != null && editableParent[variableChildKey].length == 0) }"
      >
        <p
          class="col-4 pl-0"
          style="overflow:hidden; white-sapce: nowrap; text-overflow: ellipsis"
        >
          {{ editableParent[labelField] }}
        </p>
        <input
          :id="`parent-variable-input-${editableParent[valueField]}`"
          class="col-8 form-control form-control-solid h-auto px-6 py-3"
          style="margin-left: 10px"
          v-model="model.variable"
          :disabled="submissionStates.submitting === true || localSubmissionState.submitting === true"
          :placeholder="$t('FORMS.INPUTS.VARIABLE_NAME_PROPER')"
          :maxlength="255"
          @input="filterNonAlphanumeric(null)"
        />
      </div>

      <div
        v-if="variableChildKey != null"
        class="pb-2"
      >
        <template
          v-for="(child, c) in model.children"
        >
          <div
            class="d-flex justify-content-start align-items-center py-2"
            :key="`${editableParent[valueField]}-${c}`"
          >
            <p
              class="col-4 pl-0 d-flex align-items-center"
              style="overflow:hidden; white-sapce: nowrap; text-overflow: ellipsis"
            >
              <i class="fas fa-circle pr-3" style="font-size:.35rem; color: #000000" />
              {{ child.name }}
            </p>
            <input
              :id="`child-variable-input-${c}`"
              class="col-8 form-control form-control-solid h-auto px-6 py-3"
              style="margin-left: 10px"
              v-model="model.children[c].variable"
              :disabled="submissionStates.submitting === true || localSubmissionState.submitting === true"
              :placeholder="$t('FORMS.INPUTS.VARIABLE_KEY_PROPER')"
              :maxlength="255"
              @input="filterNonAlphanumeric(c)"
            />          
          </div>
        </template>      
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations
} from "vuex";

import ErrorBox from "@/view/components/ErrorBox";

export default {
  name: "EditProjectRegistrationVariable",

  props: {
    editableParent:         { type: Object, required: false, default: null },
    valueField:             { type: String, required: false, default: "id" },
    labelField:             { type: String, required: false, default: "label" },
    variableField:          { type: String, required: false, default: "key" },
    variableChildKey:       { type: String, required: false, default: null },
    submissionStates:       { type: Object, required: true }
  },

  data() {
    return {
      model: {
        constructed: false,
        variable: "",
        children: []
      },
      localSubmissionState: {
        submitting: false
      }
    }
  },

  components: {
    ErrorBox
  },

  methods: {
    filterNonAlphanumeric: function(key) {
      if (key == null) {
        this.model.variable = this.model.variable.replace(/[^a-zA-Z0-9_\s\u0080-\uFFFF]/g, '');
      } else {
        this.model.children[key].variable = this.model.children[key].variable.replace(/[^a-zA-Z0-9_\s\u0080-\uFFFF]/g, '');
      }
    },
    cleanup: function() {
      this.model = {
        constructed: false,
        variable: "",
        children: []
      }
      this.setProjectSegmentationErrors({});
    },
    buildForm: function() {
      this.model.variable = this.editableParent[this.variableField];

      if (this.variableChildKey != null) {
        const self = this;
        this.editableParent[this.variableChildKey].forEach(function(child) {
          self.model.children.push({ id: child[self.valueField], name: child[self.labelField], variable: child[self.variableField]});
        })
      }

      this.model.constructed = true;
    },
    onSubmit: function() {
      this.localSubmissionState.submitting = true;

      let payload = {
        url: `api/v1/projects/${this.projectInfo.id}/project_segmentations/${this.editableParent[this.valueField]}/variables`,
        data: this.model
      }

      this.updateProjectSegmentationVariables(payload)
      .then((res) => {
        this.resetSegmentation(res);
        this.cleanup();
        this.$refs.modal.hide();
      }).catch((error) => {
        this.setProjectSegmentationErrors(error.response.data);
      }).finally(() => {
        this.localSubmissionState.submitting = false;
      });
    },
    ...mapActions({
      updateProjectSegmentationVariables: "updateProjectSegmentationVariables"
    }),
    ...mapMutations({
      resetSegmentation: "resetSegmentation",
      setProjectSegmentationErrors: "setProjectSegmentationErrors"
    })
  },

  watch: {
    editableParent: {
      handler() {
        this.buildForm();
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "projectSegmentationErrors"
    ])
  }
}
</script>