class ManageProject {

    //Suggests a key based on either a client or project name
    static topform(component) {
        return [
          {
            id: 1,
            name: `${component.$t("FORMS.TABS.PROJECT_DETAILS")}`,
            errors: component.tab1Errors,
            sections: [
              { 
                rows: [
                  {
                    components: [
                      {
                        name: "InputGenericCheckbox",
                        properties: {
                          class: "col-xl-12 px-5 mb-0",
                          id: 'input-published',
                          label: `${component.$t("GENERAL.STATUS.PUBLISHED")}`,
                          model: component.$v.form.published,
                          submissionStates: component.submissionStates
                        }
                      }
                    ]
                  }
                ]
              },
              {
                title: `${component.$t("FORMS.SECTION_HEADERS.PROJECT_INFO")}`,
                rows: [
                  {
                    components: [
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-8 px-5",
                          id: 'input-name',
                          alignLabel: "left",
                          showInline: false,
                          label: `${component.$t("FORMS.INPUTS.NAME")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.NAME")}`,
                          model: component.$v.form.name,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.NAME")}`
                        }
                      },
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-4 px-5 key-field",
                          id: 'input-key',
                          alignLabel: "left",
                          showInline: false,
                          label: `${component.$t("FORMS.INPUTS.URL_PREFIX")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.URL_PREFIX")}`,
                          model: component.$v.form.key,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.REQUIRED")}`,
                          disabled: component.managementType == "update"
                        }
                      }
                    ]
                  },
                  {
                    show: component.submissionType == "standard",
                    components: [
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-4 px-5",
                          id: 'input-hubspot',
                          alignLabel: "left",
                          showInline: false,
                          label: `${component.$t("FORMS.INPUTS.HUBSPOT_REFERENCE")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.HUBSPOT_REFERENCE")}`,
                          model: component.$v.form.hubspot_reference,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.REQUIRED")}`,
                          disabled: component.hs_reference_disabled,
                          maxLength: "10"
                        }
                      },
                      {
                        name: "InputGenericCheckbox",
                        properties: {
                          class: "col-xl-8 px-5 pt-10",
                          id: "input-demo",
                          label: `${component.$t("FORMS.INPUTS.DEMO_PROJECT")}`,
                          tooltip: `${component.$t("FORMS.TOOLTIPS.DEMO_PROJECT")}`,
                          model: component.$v.form.demo_project,
                          submissionStates: component.submissionStates
                        }
                      }
                    ]
                  }
                ]
              },
              {
                title: `${component.$t("FORMS.SECTION_HEADERS.PROJECT_DATES")}`,
                rows: [
                  {
                    components: [
                      {
                        on: {
                          date_updated: (value) => { component.$refs["project-topform"].$emit('start_date_updated', value) },
                        },
                        name: "InputGenericDatePicker",
                        properties: {
                          class: "col-xl-4 px-5",
                          id: 'project-input-start',
                          label: `${component.$t("FORMS.INPUTS.INPUT_START")}`,
                          model: component.$v.form.starts_on,
                          submissionStates: component.submissionStates,
                          notAfter: component.inputStartUL,
                          error: `${component.$t("FORMS.ERROR.REQUIRED")}`,
                          helper: `${component.$t("MANAGE_PROJECT.HELPERS.STARTS_ON")}`,
                          type: "datetime",
                          disabled: component.projectInfo.system_project_stage_id >= 3 || component.projectInfo.responses_count > 0
                        }
                      },
                      {
                        on: {
                          date_updated: (value) => { component.$refs["project-topform"].$emit('end_date_updated', value) },
                        },
                        name: "InputGenericDatePicker",
                        properties: {
                          class: "col-xl-4 px-5",
                          id: 'project-input-end',
                          label: `${component.$t("FORMS.INPUTS.INPUT_END")}`,
                          model: component.$v.form.ends_on,
                          submissionStates: component.submissionStates,
                          notBefore: component.inputEndLL,
                          notAfter: null,
                          error: `${component.$t("FORMS.ERROR.REQUIRED")}`,
                          helper: `${component.$t("MANAGE_PROJECT.HELPERS.ENDS_ON")}`,
                          type: "datetime"
                        }
                      },
                      {
                        on: {
                          date_updated: (value) => { component.$refs["project-topform"].$emit('admin_date_updated', value) },
                        },
                        name: "InputGenericDatePicker",
                        properties: {
                          class: "col-xl-4 px-5",
                          id: 'project-admin-end',
                          label: `${component.$t("FORMS.INPUTS.ADMIN_END")}`,
                          model: component.$v.form.admin_end_date,
                          submissionStates: component.submissionStates,
                          notBefore: component.adminEndLL,
                          notAfter: component.adminEndUL,
                          error: `${component.$t("FORMS.ERROR.REQUIRED")}`,
                          helper: `${component.$t("MANAGE_PROJECT.HELPERS.ADMIN_END")}`,
                          type: "datetime"
                        }
                      },
                    ]
                  }
                ]
              },
              {
                title: `${component.$t("FORMS.SECTION_HEADERS.PROJECT_LOCALE")}`,
                rows: [
                  {
                    components: [
                      {
                        name: "InputGenericDropdown",
                        properties: {
                          class: "col-xl-4 px-5 mb-5",
                          id: 'input-language-code',
                          label: `${component.$t("FORMS.INPUTS.LANGUAGE")}`,
                          placeholder: `${component.$t("FORMS.PLACEHOLDER.LANGUAGE")}`,
                          model: component.$v.form.system_language_code,
                          options: component.systemLanguages,
                          optionLabel: "name",
                          value: "code",
                          errorMessage: `${component.$t("FORMS.ERROR.LANGUAGE")}`,
                        }
                      },
                      {
                        name: "InputGenericDropdown",
                        properties: {
                          class: "col-xl-4 px-5 mb-5",
                          id: 'input-timezone-code',
                          label: `${component.$t("FORMS.INPUTS.TIMEZONE")}`,
                          placeholder: `${component.$t("FORMS.PLACEHOLDER.TIMEZONE")}`,
                          model: component.$v.form.system_timezone_code,
                          options: component.systemTimezones,
                          optionLabel: "name",
                          value: "code",
                          errorMessage: `${component.$t("FORMS.ERROR.TIMEZONE")}`,
                        }
                      }
                    ]
                  }
                ]
              },
              {
                title: `${component.$t("FORMS.SECTION_HEADERS.MISCELLANEOUS")}`,
                rows: [
                  {
                    components: [
                       {
                        name: "InputGenericDropdown",
                        show: component.submissionType == "standard",
                        properties: {
                          class: "col-xl-4 pr-3 px-5",
                          id: 'input-deal-owner',
                          label: `${component.$t("FORMS.INPUTS.DEAL_OWNER")}`,
                          model: component.$v.form.deal_owner,
                          options: component.userList,
                          optionLabel: "calculatedName",
                          value: "id",
                          errorMessage: `${component.$t("FORMS.ERROR.REQUIRED")}`,
                        }
                      },
                      {
                        name: "InputGenericDropdown",
                        properties: {
                          class: "col-xl-4 px-5 mb-5",
                          id: 'input-participant-limit',
                          label: `${component.$t("FORMS.INPUTS.PARTICIPANT_LIMIT")}`,
                          model: component.$v.form.participant_limit,
                          options: component.supportedParticipantsList,
                          optionLabel: "name",
                          value: "id",
                          errorMessage: `${component.$t("FORMS.ERROR.REQUIRED")}`,
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            id: 2,
            name: `${component.$t("FORMS.TABS.PROJECT_REGISTRATION_OPTIONS")}`,
            show: component.managementType == "update",
            sections: [
              {
                title: `${component.$t("FORMS.SECTION_HEADERS.REGISTRATION_OPTIONS")}`,
                show: component.managementType == "update",
                rows: [
                  {
                    components: [
                      {
                        on: {
                          registration_option_change: (value) => { component.$refs["project-topform"].$emit('registration_option_change', value) }
                        },
                        name: "RegistrationOptions",
                        properties: {
                          class: "col-xl-12 pr-3 px-5 mb-10",
                          id: 'input-register-options-anon',
                          type: 2,
                          variables: component.form.registration_variables
                        }
                      },
                      {
                        on: {
                          registration_option_change: (value) => { component.$refs["project-topform"].$emit('registration_option_change', value) }
                        },
                        name: "RegistrationOptions",
                        properties: {
                          class: "col-xl-12 px-5 mb-5",
                          id: 'input-register-options-otf',
                          type: 3,
                          helper: true,
                          variables: component.form.registration_variables
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
    }
  
  }
  
export default ManageProject;